import axios from "axios";
export default (ruta = "") => {

  let rutaApi = ""
  if (ruta == "1") {
  } else {

    //rutaApi = "http://localhost/proyecto_llaves/public/api/" //Santiago 

    rutaApi = "https://sigall.cloudsenactpi.net/api/"
    
  }

  return axios.create({
    baseURL: rutaApi,    
  });
};
