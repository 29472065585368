<template>
  <div class="container">
    <div class="loader" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<style>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 14px;
  position: relative;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #fd671a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (max-width: 717px) {
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
  .loader {
    margin: 30px auto;
    font-size: 10px;
    position: relative;
    border-top: 0.8em solid rgba(0, 0, 0, 0.2);
    border-right: 0.8em solid rgba(0, 0, 0, 0.2);
    border-bottom: 0.8em solid rgba(0, 0, 0, 0.2);
    border-left: 0.8em solid #fd671a;
  }
}
</style>
